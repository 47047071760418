import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCollection = (collection) => {
    const documents = ref(null)
    const error = ref(null)

    let collectionRef = projectFirestore.collection(collection)
        .orderBy('createdAt')

    //onSnapshot returns a function that when invoked unsubs from said collection
    const unsub = collectionRef.onSnapshot((snap) => {
        console.log("snapshot")
        let results = []
        snap.docs.forEach((doc) => {
            doc.data().createdAt && results.push({ ...doc.data(), id: doc.id })
        })
        documents.value = results
        error.value = null
    }, (err) => {
        console.log(err.message)
        error.value = "Could not fetch data"
        documents.value = null
    })

    //onInvalidate will run when the parent component unmounts
    watchEffect((onInvalidate) => {
        onInvalidate(() => {
            unsub()
        })
    })

    return { documents, error }
}

export default getCollection