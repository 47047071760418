<template>
  <div class="container">
      <NavBar />
      <ChatWindow />
      <NewChatForm />
  </div>
</template>

<script>
import { watchEffect } from 'vue';
import NavBar from "../components/NavBar";
import getUser from "../composables/getUser"
import { useRouter } from 'vue-router';
import NewChatForm from '../components/NewChatForm'
import ChatWindow from '../components/ChatWindow'

export default {
  components: { NavBar, NewChatForm, ChatWindow },
  setup() {
      const { user } = getUser()
      const router = useRouter()

      watchEffect(() => {
          if(!user.value){
              router.push({ name: 'Welcome' })
          }
      })

      return { user }
  },
};
</script>

<style>
</style>