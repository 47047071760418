import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyBqpfAoihBolscH9um-1W0mgaOKzVqbRFk",
    authDomain: "udemy-vue-firebase-sites-b68d5.firebaseapp.com",
    projectId: "udemy-vue-firebase-sites-b68d5",
    storageBucket: "udemy-vue-firebase-sites-b68d5.appspot.com",
    messagingSenderId: "655226046055",
    appId: "1:655226046055:web:5bb48537896f66964136c2",
    measurementId: "G-W78HCWQ5CN"
  }

  // init firebase
  firebase.initializeApp(firebaseConfig)

  // init firestore
  const projectAuth = firebase.auth()
  const projectFirestore = firebase.firestore()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp


  export {projectFirestore, projectAuth, timestamp}