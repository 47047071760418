import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import ChatRoom from '../views/ChatRoom.vue'
import { projectAuth } from '../firebase/config'

// auth guard
// to = the component you're going to
// from = the component/route you've come from
// next = a Function that allows the user to enter or redirect
const requiredAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if(!user){
    next({ name: 'Welcome' })
  } else {
    next()
  }
}

const isLoggedIn = (to, from, next) => {
  let user = projectAuth.currentUser
  if(user){
    next({ name: 'ChatRoom' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: isLoggedIn
  },
  {
    path: '/chatroom',
    name: 'ChatRoom',
    component: ChatRoom,
    beforeEnter: requiredAuth
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
